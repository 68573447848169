import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "index" */ '../pages/index.vue'),
  },
  {
    path: '/same-radical-words',
    name: 'SameRadicalWords',
    component: () => import(/* webpackChunkName: "same-radical-words" */ '../pages/same-radical-words.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
